import { Box, BoxProps } from "@chakra-ui/react"
import { Link as RemixLink } from "@remix-run/react"
import { ReactNode } from "react"
import { useBgColor } from "~/utils/hooks/useBgColor"

export interface CardProps extends BoxProps {
  children?: ReactNode
  suppressHover?: boolean
  to?: string
}

export const Card = ({
  children,
  suppressHover,
  to,
  ...props
}: CardProps): JSX.Element => {
  const bgColor = useBgColor()

  return (
    <Box
      rounded="xl"
      borderWidth={1}
      borderColor="gray.100"
      p={5}
      as="article"
      bgColor={bgColor}
      boxShadow="card"
      pos="relative"
      zIndex={1}
      {...(suppressHover
        ? {}
        : {
            _hover: { boxShadow: "cardHover" },
            transition: "box-shadow 0.2s",
          })}
      {...props}
    >
      {to && <Box as={RemixLink} to={to} pos="absolute" inset={0} zIndex={0} />}
      {children}
    </Box>
  )
}
